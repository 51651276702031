import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

enum COUPON_VALUE_TYPE {
  FLAT_VALUE = 'flat_value',
  PERCENT_VALUE = 'percent_value',
  OTHER_BENEFIT = 'other_benefit',
}

const COUPON_VALUE_TYPE_DICT: {
  [K: string]: string;
} = {
  [COUPON_VALUE_TYPE.FLAT_VALUE]: 'Stała wartość',
  [COUPON_VALUE_TYPE.PERCENT_VALUE]: 'Wartość procentowa',
  [COUPON_VALUE_TYPE.OTHER_BENEFIT]: 'Inne benefity',
};

const COUPON_VALUE_TYPE_OPTIONS = Object.keys(COUPON_VALUE_TYPE_DICT).map(
  (key) => ({
    value: key,
    label: COUPON_VALUE_TYPE_DICT[key],
  }),
);

const BODY_SCHEMA = yup.object({
  coupons: yup
    .array()
    .of(
      yup.object().shape({
        code: yup.string().required(ERROR_MESSAGES.required),
        description: yup.string(),
        validTo: yup.date().nullable().required(ERROR_MESSAGES.required),
        value: yup
          .number()
          .nullable()
          .when('valueType', {
            is: (valueType: string) =>
              valueType !== COUPON_VALUE_TYPE.OTHER_BENEFIT,
            then: (schema) =>
              schema
                .typeError(ERROR_MESSAGES.invalidNumberType)
                .required(ERROR_MESSAGES.required),
          }),
        valueType: yup.string().required(ERROR_MESSAGES.required),
        couponPool: yup.string().required(ERROR_MESSAGES.required),
        isShareable: yup.boolean().required(ERROR_MESSAGES.required),
      }),
    )
    .required(),
});

const DEFAULT_VALUES = {
  coupons: [
    {
      code: '',
      description: '',
      validTo: undefined,
      value: 0,
      valueType: '',
      pool: '',
      isShareable: false,
    },
  ],
};

export {
  COUPON_VALUE_TYPE,
  COUPON_VALUE_TYPE_DICT,
  COUPON_VALUE_TYPE_OPTIONS,
  BODY_SCHEMA,
  DEFAULT_VALUES,
};

import { AccordionItem, AccordionPanel, Stack } from '@chakra-ui/react';
import { FormBodyProps } from 'types/common';
import Card from 'components/Card';
import LoadingSpinner from 'components/LoadingSpinner';
import AccordionButton from 'components/AccordionButton';
import Input from 'components/Form/Input';
import Switch from 'components/Form/Switch';
import Select from 'components/Form/Select';
import { COUPON_VALUE_TYPE_OPTIONS } from 'pages/Coupons/Form/Create/constants';

interface CouponPoolsFormBodyProps extends FormBodyProps {
  isSystemPool: boolean;
}

function FormBody({
  isLoading,
  isSystemPool: isDefaultPool,
}: CouponPoolsFormBodyProps) {
  return (
    <Card>
      {isLoading && <LoadingSpinner />}
      <AccordionItem>
        <AccordionButton title="Pula kuponów" />
        <AccordionPanel>
          <Stack spacing={4}>
            {!isDefaultPool && (
              <>
                <Input name="label" label="Nazwa" placeholder="Wpisz nazwę" />
                <Input
                  name="qrCode"
                  label="Kod QR"
                  placeholder="Wpisz nazwę, aby wygenerować kod"
                  isReadOnly
                />
                <Select
                  name="defaultCouponValueType"
                  label="Domyślny typ wartości kuponu"
                  options={COUPON_VALUE_TYPE_OPTIONS}
                />
                <Input
                  name="defaultCouponValue"
                  label="Domyślna wartość kuponu"
                  placeholder="Wpisz wartość"
                  type="number"
                />
                <Input
                  name="maxCouponsPerUser"
                  label="Maksymalna liczba kuponów na użytkownika"
                  placeholder="Wpisz wartość"
                  type="number"
                />
              </>
            )}
            <Switch
              name="alertIfTooFewCoupons"
              label="Powiadomienie, jeśli zostało mało kuponów"
            />
            <Switch name="isShareable" label="Możliwość udostępniania" />
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Card>
  );
}

export default FormBody;

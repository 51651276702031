import { ColumnDef } from '@tanstack/react-table';
import QrCodePreviewModal from 'components/QrCodePreviewModal';
import { datePrettier } from 'utils/date';
import StatsTooltip from './components/StatsTooltip';

const MAX_COUPONS_PER_USER_LIMIT = 999999;

function GetColumns(): ColumnDef<CouponPool, any>[] {
  return [
    {
      header: 'Nazwa',
      accessorKey: 'label',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Kod QR',
      cell: ({
        row: {
          original: { label, qrCode, isSystemPool },
        },
      }) => (
        <QrCodePreviewModal
          label={label}
          qrCodeValue={qrCode}
          isSystemPool={isSystemPool}
        />
      ),
    },
    {
      header: 'Data utworzenia',
      accessorKey: 'createdAt',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'Domyślna wartość kuponu',
      accessorKey: 'defaultCouponValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Maksymalna liczba kuponów na użytkownika',
      accessorKey: 'maxCouponsPerUser',
      cell: (info) =>
        info.getValue() === MAX_COUPONS_PER_USER_LIMIT ? '∞' : info.getValue(),
    },
    {
      header: 'Statystyki',
      cell: ({
        row: {
          original: { createdAt, label },
        },
      }) => <StatsTooltip createdAt={createdAt} couponPool={label} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
